import React, { Suspense } from 'react';
import {
  MdHome,
  MdDataExploration,
  MdLock,
  MdNewspaper,
  MdCloud,
  MdFindInPage,
  MdDesignServices,
  MdOutlineMap,
  MdManageSearch,
  MdListAlt,
  MdScreenSearchDesktop,
  MdOutlineRefresh,
  MdInsights,
  MdOutlineHealthAndSafety,
  MdOutlineLandscape,
  MdCardMembership,
} from 'react-icons/md';
import { AiOutlineDollar } from 'react-icons/ai';
import { FaFileInvoiceDollar } from 'react-icons/fa6';
import cloudPatrolImage from '../src/assets/img/CloudPatroltest.png';
import tagorbit from '../src/assets/img/tagorbit.webp';
const DashboardsDefault = React.lazy(() =>
  import('views/admin/dashboards/default')
);
const SpendInsight = React.lazy(() => import('views/admin/SpendInsight'));
const ForgotPasswordCentered = React.lazy(() =>
  import('views/auth/forgotPassword/ForgotPasswordCenter.jsx')
);
const ForgotPasswordDefault = React.lazy(() =>
  import('views/auth/forgotPassword/ForgotPasswordDefault.jsx')
);
const LockCentered = React.lazy(() => import('views/auth/lock/LockCenter.jsx'));
const LockDefault = React.lazy(() => import('views/auth/lock/LockDefault.jsx'));
const SignInCentered = React.lazy(() =>
  import('views/auth/signIn/SignInCenter.jsx')
);
const SignInDefault = React.lazy(() =>
  import('views/auth/signIn/SignInDefault.jsx')
);
const Signinnew = React.lazy(() => import('views/auth/signIn/Signinnew.jsx'));
const SignUpCentered = React.lazy(() =>
  import('views/auth/signUp/SignUpCenter.jsx')
);
const SignUpDefault = React.lazy(() =>
  import('views/auth/signUp/SignUpDefault.jsx')
);
const Signupnew = React.lazy(() => import('views/auth/signUp/Signupnew'));
const VerificationCentered = React.lazy(() =>
  import('views/auth/verification/VerificationCenter.jsx')
);
const VerificationDefault = React.lazy(() =>
  import('views/auth/verification/VerificationDefault.jsx')
);
const TagDiscovery = React.lazy(() =>
  import('views/TagOrbit/Tag Discovery/TagDiscovery')
);
const EnvSummary = React.lazy(() =>
  import('views/TagOrbit/Tag Insights/TagLandscape')
);
const TagHealth = React.lazy(() =>
  import('views/TagOrbit/Tag Insights/TagHealth')
);
const TagFusion = React.lazy(() =>
  import('views/TagOrbit/Tag Insights/TagFusion')
);
const ContactAdmin = React.lazy(() => import('./ContactAdmin'));
const Users = React.lazy(() => import('./views/admin/users/users'));
const RolesRes = React.lazy(() => import('views/auth/Roles/RolesResp'));
const AddRole = React.lazy(() => import('views/auth/Roles/AddRole'));
const CompanyInfo = React.lazy(() => import('views/auth/Company/CompanyInfo'));
const Profilesettings = React.lazy(() =>
  import('views/admin/SpendInsight/Profilesettings')
);
const SVGComponent = React.lazy(() =>
  import('views/admin/SpendInsight/SVGComponent')
);
const BillSummary = React.lazy(() => import('views/admin/billsummary'));
const Healthcheck = React.lazy(() => import('views/auth/Healthcheck'));
const SavingSimulation = React.lazy(() =>
  import('views/admin/SavingSimulations')
);
const PushNotifications = React.lazy(() =>
  import('views/auth/PushNotifications')
);
const Pushhistory = React.lazy(() => import('views/auth/Pushhistory'));
const Neworganization = React.lazy(() => import('views/auth/Neworganization'));
const Alerts = React.lazy(() => import('views/admin/main/others/404'));
const TestGraphs = React.lazy(() => import('views/admin/TestGraphs'));
const DrillDownGraphs = React.lazy(() => import('views/admin/drilldowncharts'));
const TagRefresh = React.lazy(() =>
  import('views/TagOrbit/Tag Insights/TagRefersh')
);
const TooltipGraphs = React.lazy(() => import('views/admin/TooltipGraphs'));
const BillConfiguration = React.lazy(() => import('views/admin/billconfig'));
const SettingsPage = React.lazy(() => import('views/admin/settings'));
const BillDetail = React.lazy(() => import('views/admin/billconfigdetail'));
const CloudPatrol = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatrol')
);
const CloudPatrolComplaince = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatrolComplaince')
);
const CloudPatroloveriew = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatroloveriew')
);
const CloudPatrolservice = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatrolservice')
);
const CloudPatrolregion = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatrolRegion')
);
const CloudPatrolfinding = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatrolFinding')
);
const CloudPatrolMuteFileCreate = React.lazy(() =>
  import('views/admin/SpendInsight/CloudPatrolMuteFileCreate')
);
const userdetails = localStorage.getItem('UserDetails');
const userinfo = localStorage.getItem('UserInfo');
const url = window.location.pathname; // Get only the path after the hostname
const path = url.split('/').slice(1).join('/'); // Remove the first empty element and join the rest
console.log('Path', path);
const finalpath = '/' + path;
console.log(finalpath); // Outputs: "admin/CloudPatrolOverview"

let onboard = '';
let companyType = null;

if (userdetails) {
  const parsedUserDetails = JSON.parse(userdetails);
  onboard = parsedUserDetails?.onboard || '';
}

if (userinfo) {
  const parsedUserInfo = JSON.parse(userinfo);
  companyType = parsedUserInfo[0]?.type_of_company || null;
}

if (
  !userdetails &&
  path !== 'auth/sign-in' &&
  path !== 'auth/forgot-password/default' &&
  path !== 'auth/sign-up/default' &&
  path !== ''
) {
  localStorage.setItem('URL', finalpath);
}

const routes = [
  {
    name: 'Profile Settings',
    layout: '/admin',
    path: '/profilesettings',
    component: (
      <Suspense>
        <Profilesettings />
      </Suspense>
    ),
    icon: <SVGComponent className="text-inherit h-5 w-5" />,
    hidden: true,
  },

  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards/default',
    component: (
      <Suspense>
        <DashboardsDefault />
      </Suspense>
    ),
    icon: <MdHome className="text-inherit h-5 w-5" />,
  },
  {
    name: 'Graphs',
    layout: '/admin',
    path: '/graphs',
    component: (
      <Suspense>
        <TestGraphs />
      </Suspense>
    ),
    icon: <MdHome className="text-inherit h-5 w-5" />,
    hidden: true,
  },
  {
    name: 'Spend Insight',
    layout: '/admin',
    path: '/Spend_Insight',
    component: (
      <Suspense>
        <SpendInsight />
      </Suspense>
    ),
    icon: <MdDataExploration className="text-inherit h-5 w-5" />,
  },
  {
    name: 'Contact Admin',
    layout: '/admin',
    path: '/contactadmin',
    component: (
      <Suspense>
        <ContactAdmin />
      </Suspense>
    ),
    icon: <MdHome className="text-inherit h-5 w-5" />,
    hidden: true,
  },
  {
    name: 'Cloud Patrol',
    path: '/cloud_patrol',
    // icon:
    // <CloudPatrolIcon className="text-inherit h-5 w-5" />,
    icon: (
      <img
        src={cloudPatrolImage}
        alt="Cloud Patrol Icon"
        className="text-inherit h-6 w-7 mr-4"
      />
    ),
    collapse: onboard === 'NO' ? false : true,
    items: [
      {
        name: 'Overview',
        layout: '/admin',
        path: '/CloudPatrolOverview',
        icon: <MdCloud className="text-inherit h-5 w-5" />,
        component: (
          <Suspense>
            <CloudPatrol />
          </Suspense>
        ),
        hidden: onboard === 'NO',
      },
      {
        name: 'CloudPatroloveriew',
        layout: '/admin',
        path: '/CloudPatroloveriew',
        component: (
          <Suspense>
            <CloudPatroloveriew />
          </Suspense>
        ),
        // icon: <CloudPatrolIcon className="text-inherit h-5 w-5" />,
        icon: (
          <img
            src={cloudPatrolImage}
            alt="Cloud Patrol Icon"
            className="text-inherit h-5 w-5"
          />
        ),
        hidden: true, // Add hidden flag here,
      },
      {
        name: 'Compliance',
        layout: '/admin',
        path: '/CloudPatrolcomplaince',
        component: (
          <Suspense>
            <CloudPatrolComplaince />
          </Suspense>
        ),
        icon: <MdFindInPage className="text-inherit h-5 w-5" />,
        hidden: onboard === 'NO',
      },
      {
        name: 'Service',
        layout: '/admin',
        path: '/CloudPatrolservice',
        component: (
          <Suspense>
            <CloudPatrolservice />
          </Suspense>
        ),
        icon: <MdDesignServices className="text-inherit h-5 w-5" />,
        hidden: onboard === 'NO',
      },
      {
        name: 'Region',
        layout: '/admin',
        path: '/CloudPatrolregion',
        component: (
          <Suspense>
            <CloudPatrolregion />
          </Suspense>
        ),
        icon: <MdOutlineMap className="text-inherit h-5 w-5" />,
        hidden: onboard === 'NO',
      },
      {
        name: 'Findings Overview',
        layout: '/admin',
        path: '/CloudPatrolfinding',
        component: (
          <Suspense>
            <CloudPatrolfinding />
          </Suspense>
        ),
        icon: <MdManageSearch className="text-inherit h-5 w-5" />,
        hidden: onboard === 'NO',
      },
      {
        name: 'Muted-Configuration',
        layout: '/admin',
        path: '/CloudPatrolMutelistCreate',
        component: (
          <Suspense>
            <CloudPatrolMuteFileCreate />
          </Suspense>
        ),
        icon: <MdListAlt className="text-inherit h-5 w-5" />,
        hidden: onboard === 'NO',
      },
    ],
  },
  {
    name: 'Tag Orbit',
    path: '/tag-m',
    icon: (
      <img src={tagorbit} alt="tagorbit" className="text-inherit h-6 w-7 mr-4" />
    ),
    collapse: onboard === 'NO' ? false : true,
    items: [
      {
        name: 'Tag Discovery',
        layout: '/admin',
        path: '/TagDiscovery',
        component: (
          <Suspense>
            <TagDiscovery />
          </Suspense>
        ),
        icon: <MdScreenSearchDesktop className="text-inherit h-5 w-5" />,
        hidden: onboard === 'NO',
      },
      {
        name: 'Tag Insights',
        path: '/tagint',
        collapse: true,
        hidden: onboard === 'NO',
        icon: <MdInsights className="text-inherit h-5 w-5" />,
        items: [
          {
            name: 'Tag Landscape',
            layout: '/admin',
            path: '/tag-int/env-sum',
            component: (
              <Suspense>
                <EnvSummary />
              </Suspense>
            ),
            secondary: true,
            hidden: onboard === 'NO',
            icon: <MdOutlineLandscape className="text-inherit h-5 w-5" />,
          },
          {
            name: 'Tag Health',
            layout: '/admin',
            path: '/tag-int/tag-health',
            component: (
              <Suspense>
                <TagHealth />
              </Suspense>
            ),
            secondary: true,
            hidden: onboard === 'NO',
            icon: <MdOutlineHealthAndSafety className="text-inherit h-5 w-5" />,
          },
          {
            name: 'Tag Fusion',
            layout: '/admin',
            path: '/tag-int/tag-con',
            component: (
              <Suspense>
                <TagFusion />
              </Suspense>
            ),
            secondary: true,
            hidden: onboard === 'NO',
            icon: <MdCardMembership className="text-inherit h-5 w-5" />,
          },
          {
            name: 'Tag Refresh',
            layout: '/admin',
            path: '/tag-int/tag-Refresh',
            component: (
              <Suspense>
                <TagRefresh />
              </Suspense>
            ),
            secondary: true,
            hidden: onboard === 'NO',
            icon: <MdOutlineRefresh className="text-inherit h-5 w-5" />,
          },
        ],
      },
    ],
  },
  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    hidden: true,
    items: [
      {
        name: companyType === 1 ? null : 'Push Notifications',
        layout: '/admin',
        path: '/settings/pushnotifications',
        component:
          companyType === 1 ? (
            <Suspense fallback={<div>Loading Alerts...</div>}>
              <Alerts />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading Push Notifications...</div>}>
              <PushNotifications />
            </Suspense>
          ),
        hidden: onboard === 'NO' || companyType === 1,
      },

      {
        name: companyType === 1 ? null : 'Notifications history',
        layout: '/admin',
        path: '/settings/pushnotifications/history',
        component:
          companyType === 1 ? (
            <Suspense fallback={<div>Loading Alerts...</div>}>
              <Alerts />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading Push history...</div>}>
              <Pushhistory />
            </Suspense>
          ),
        hidden: true || onboard === 'NO' || companyType === 1,
      },

      {
        name: companyType === 1 ? null : 'New Organization',
        layout: '/admin',
        path: '/settings/neworganization',
        component:
          companyType === 1 ? (
            <Suspense fallback={<div>Loading Alerts...</div>}>
              <Alerts />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading Push Neworganization...</div>}>
              <Neworganization />
            </Suspense>
          ),
        hidden: onboard === 'NO' || companyType === 1,
      },

      {
        name: companyType === 2 ? null : 'Health Check',
        layout: '/admin',
        path: '/healthcheck',
        component:
          companyType === 1 ? (
            <Suspense fallback={<div>Loading Alerts...</div>}>
              <Alerts />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading Push Healthcheck...</div>}>
              <Healthcheck />
            </Suspense>
          ),
        hidden: onboard === 'NO' || companyType === 2,
      },
      {
        name: 'Users',
        layout: '/admin',
        path: '/settings/users',
        component: (
          <Suspense>
            <Users />
          </Suspense>
        ),
        hidden: onboard === 'NO',
      },
      {
        name: 'Role & Permissions',
        layout: '/admin',
        path: '/settings/RolesRes',
        component: (
          <Suspense>
            <RolesRes />
          </Suspense>
        ),
        hidden: onboard === 'NO',
      },
      {
        name: '',
        layout: '/admin',
        path: '/AddRole',
        component: (
          <Suspense>
            <AddRole />
          </Suspense>
        ),
        hidden: true,
      },
      {
        name: 'Company Info',
        layout: '/admin',
        path: '/settings/companyInfo',
        component: (
          <Suspense>
            <CompanyInfo />
          </Suspense>
        ),
      },
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        hidden: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in',
            component: (
              <Suspense>
                <Signinnew />
              </Suspense>
            ),
          },
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in2',
            component: (
              <Suspense>
                <SignInDefault />
              </Suspense>
            ),
          },
        ],
      },
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        hidden: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default',
            component: (
              <Suspense>
                <Signupnew />
              </Suspense>
            ),
          },

          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default2',
            component: (
              <Suspense>
                <SignUpDefault />
              </Suspense>
            ),
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-up/centered',
            component: (
              <Suspense>
                <SignUpCentered />
              </Suspense>
            ),
          },
        ],
      },
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        hidden: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: (
              <Suspense>
                <VerificationDefault />
              </Suspense>
            ),
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: (
              <Suspense>
                <VerificationCentered />
              </Suspense>
            ),
          },
        ],
      },
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        hidden: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: (
              <Suspense>
                <LockDefault />
              </Suspense>
            ),
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: (
              <Suspense>
                <LockCentered />
              </Suspense>
            ),
          },
        ],
      },
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        hidden: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: (
              <Suspense>
                <ForgotPasswordDefault />
              </Suspense>
            ),
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: (
              <Suspense>
                <ForgotPasswordCentered />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    name: 'Bill Summary',
    layout: '/admin',
    path: '/bill_summary',
    component: (
      <Suspense>
        <BillSummary />
      </Suspense>
    ),
    icon: <MdNewspaper className="text-inherit h-5 w-5" />,
  },
  {
    name: companyType === 1 ? null : 'Saving Simulations',
    layout: '/admin',
    path: '/saving_simulations',
    icon: <AiOutlineDollar className="text-inherit h-5 w-5" />,
    component:
      companyType === 1 ? (
        <Suspense fallback={<div>Loading Alerts...</div>}>
          <Alerts />
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading Push Notifications...</div>}>
          <SavingSimulation />
        </Suspense>
      ),
    hidden: companyType === 1,
  },
  {
    name: 'DrillDown Graphs',
    layout: '/admin',
    path: '/drilldowngraphs',
    component: (
      <Suspense>
        <DrillDownGraphs />
      </Suspense>
    ),
    icon: <AiOutlineDollar className="text-inherit h-5 w-5" />,
  },
  {
    name: 'Tooltip Graphs',
    layout: '/admin',
    path: '/tooltipgraphs',
    component: (
      <Suspense>
        <TooltipGraphs />
      </Suspense>
    ),
    icon: <AiOutlineDollar className="text-inherit h-5 w-5" />,
  },
  {
    name: 'Bill Configuration',
    layout: '/admin',
    path: '/settings/billconfig',
    component: (
      <Suspense>
        <BillConfiguration />
      </Suspense>
    ),
    icon: <FaFileInvoiceDollar className="text-inherit h-5 w-5" />,
    hidden: true,
  },
  {
    name: 'Bill Configuration Detail',
    layout: '/admin',
    path: '/settings/BillDetail',
    component: (
      <Suspense>
        <BillDetail />
      </Suspense>
    ),
    icon: <FaFileInvoiceDollar className="text-inherit h-5 w-5" />,
    hidden: true,
  },
  {
    name: 'Settings',
    layout: '/admin',
    path: '/settings',
    component: (
      <Suspense>
        <SettingsPage />
      </Suspense>
    ),
    icon: <FaFileInvoiceDollar className="text-inherit h-5 w-5" />,
    hidden: true,
  },
];
export default routes;
