// src/components/fixedPlugin/FixedPlugin.jsx

import React from 'react';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { useDarkMode } from '../../contexts/DarkModeContext';

export default function FixedPlugin() {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <>
      {/* Footer button to show/hide dark mode toggle */}
      {/* <button
        className="fixed bottom-[30px] right-[35px] !z-[99] flex h-[60px] w-[60px] items-center justify-center rounded-full border-[#6a53ff] bg-gradient-to-br from-brandLinear to-blueSecondary p-0"
        onClick={() => setIsVisible(!isVisible)}
      >
        <div className="cursor-pointer text-gray-600">
          <RiMoonFill className="h-4 w-4 text-white" />
        </div>
      </button> */}

      {/* Dark mode toggle button */}
      {/* {isVisible && (
        <button
          className="border-px fixed bottom-[30px] right-[35px] !z-[99] flex h-[60px] w-[60px] items-center justify-center rounded-full border-[#6a53ff] bg-gradient-to-br from-brandLinear to-blueSecondary p-0"
          onClick={toggleDarkMode}
        >
          <div className="cursor-pointer text-gray-600">
            {darkMode ? (
              <RiSunFill className="h-4 w-4 text-white" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-white" />
            )}
          </div>
        </button>
      )} */}
    </>
  );
}
