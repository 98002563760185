import React, { useEffect, useState, useRef, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
  IoMdSettings,
} from 'react-icons/io';
import { FiSearch, FiX } from 'react-icons/fi';
import { LOGOUT } from 'API/api';
import { post, get } from 'API/apiCommon';
import { useDarkMode } from 'contexts/DarkModeContext';
import bellicon from '../../assets/img/bell_icon.webp';
import alert from '../../assets/img/alert_message.webp';
import normal from '../../assets/img/normal_message.webp';
import {
  NOTIFICATIONS,
  NOTIFICATIONS_STATUS,
  ORGANIZATION,
  EMP_GET_ACC_COMP_ID,
} from 'API/api';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import newaws from '../../assets/img/newaws.webp';
import gcp from '../../assets/img/gcp.webp';
import azure from '../../assets/img/azure.webp';
import dropd from '../../assets/img/dropd.webp';
// Lazy load components
const Dropdown = React.lazy(() => import('components/dropdown'));
const Configurator = React.lazy(() => import('./Configurator'));
// Lazy load images using dynamic imports (optional based on use case)

const Navbar = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { onOpenSidenav, brandText, mini, hovered } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark')
  );
  const logout = () => {
    post(LOGOUT).then((response) => {
      if (response.data.code === 200) {
        alert(response.data.message);
      }
    });
    localStorage.removeItem('UserDetails');
    localStorage.removeItem('UserInfo');
    localStorage.removeItem('AccountID');
    localStorage.removeItem('AccountNO');
    localStorage.removeItem('URL');
    localStorage.removeItem('SelectedAccountID');
    localStorage.removeItem('SelectedAccountNO');
    localStorage.removeItem('sidebarState');
  };
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState([]);
  const { picture } = useDarkMode();
  const [parsedUserInfo, setParsedUserInfo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState([]);
  const dropdownRef = useRef(null);
  const [AccsearchTerm, setAccSearchTerm] = useState('');
  const [EmpsearchTerm, setEmpSearchTerm] = useState('');
  const [tableData, setTableData] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedorgname, setselectedorgname] = useState(null);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOrgSelected, setIsOrgSelected] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showempdrop, setshowempdrop] = useState(false);
  useEffect(() => {
    handletabledata();
  }, []);

  useEffect(() => {
    // Define the required localStorage keys
    const requiredKeys = [
      'UserDetails',
      'UserInfo',
      'AccountID',
      'AccountNO',
      // 'URL',
      // 'SelectedAccountID',
      // 'SelectedAccountNO',
    ];

    // Function to check for null or undefined values in localStorage
    const validateLocalStorage = () => {
      for (let key of requiredKeys) {
        const value = localStorage.getItem(key);
        // If any key has a value of null or undefined, clear localStorage and redirect to login
        if (value === null || value === 'undefined') {
          console.warn(
            `Missing or invalid value for ${key}. Redirecting to login.`
          );
          localStorage.clear();
          navigate('/auth/sign-in');
          return; // No need to check further keys if one is invalid
        }
      }
    };

    // Set a 10-second timeout after the first render to validate localStorage
    const timeoutId = setTimeout(() => {
      validateLocalStorage();
    }, 1000); // 10000ms = 10 seconds

    // Cleanup timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  const handletabledata = () => {
    get(ORGANIZATION)
      .then((response) => {
        const data = response.data.results || [];
        setTableData(data);
        if (data.length > 0) {
          const firstCompany = data[0];
          //console.log('First Company:', firstCompany);
          handleaccounts(firstCompany.company_id);
          setselectedorgname(firstCompany);
        }
      })
      .catch((error) => {
        console.error('Error fetching organization data:', error);
      });
  };
  const handleaccounts = async (company_id) => {
    const payload = { company_id };
    try {
      const response = await post(EMP_GET_ACC_COMP_ID, payload);
      const data = response.data.data || [];
      if (data.length > 0) {
        setAccountOptions(data); // Update account options
        setIsAccountDropdownOpen(true); // Open account dropdown after data fetch
        setshowcompany(false);
        setshowacc(true);
      } else {
        setAccountOptions([]); // Clear options if none available
        setIsAccountDropdownOpen(false);
        setshowcompany(false);
        setshowacc(false);
        setIsOrgDropdownOpen(true);
      }
      console.log('Updated Account Options:', data);
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  useEffect(() => {
    // Retrieve user information from localStorage
    const userInfo = JSON.parse(localStorage.getItem('UserInfo'));
    const storedAccountID = JSON.parse(
      localStorage.getItem('SelectedAccountID')
    );
    const storedAccountNO = JSON.parse(
      localStorage.getItem('SelectedAccountNO')
    );

    // Ensure userInfo exists and type_of_company is 2
    if (userInfo && userInfo[0].type_of_company === 2) {
      // Check if there are account options
      if (accountOptions.length > 0) {
        const defaultAccount = accountOptions[0]; // Get the first account as the default
        // selectedAccount,
        console.log('storedAccountNO', storedAccountNO);
        // If both storedAccountID and storedAccountNO exist, update the values in localStorage
        if (storedAccountID && storedAccountNO) {
          localStorage.setItem('AccountID', JSON.stringify(storedAccountID));
          localStorage.setItem('AccountNO', JSON.stringify(storedAccountNO));
          const storedAccount = accountOptions.find(
            (acc) => acc.account_id === storedAccountNO
          );
          console.log('storedAccount', storedAccount);
          setSelectedAccount(storedAccount);
        } else {
          // If no values are in localStorage, set the first account as default
          setSelectedAccount(defaultAccount);

          // Set default values in localStorage
          localStorage.setItem(
            'AccountID',
            JSON.stringify(defaultAccount.accountID_id)
          );
          localStorage.setItem(
            'AccountNO',
            JSON.stringify(defaultAccount.account_id)
          );
        }
      }
    }
  }, [accountOptions]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('UserInfo'));
    const userinfo = localStorage.getItem('UserInfo');
    const userdata = JSON.parse(userinfo);
    setUser(userdata[0]);
    setProfile(picture ? picture : userdata[0].profile_pic);
    if (localStorage.getItem('AccountID') === 'undefined') {
      localStorage.removeItem('AccountID');
    }
    if (userInfo) {
      setParsedUserInfo(userInfo);
      const storedAccountID = localStorage.getItem('AccountID');
      let parsedAccountID = null;

      if (storedAccountID) {
        try {
          parsedAccountID = JSON.parse(storedAccountID);
        } catch (error) {
          console.error('Error parsing JSON from localStorage', error);
          parsedAccountID = null; // Reset to null if parsing fails
        }
      }
      const account =
        userInfo[0]?.Account_information.find(
          (acc) => acc.id === parsedAccountID
        ) || userInfo[0]?.Account_information[0];
      if (account) {
        setSelectedAccount(account);
        localStorage.setItem('AccountID', JSON.stringify(account.id));
        localStorage.setItem('AccountNO', JSON.stringify(account.Account_id));
      }
    }
  }, [picture]);
  const handleprofile = () => {
    navigate('/admin/profilesettings');
  };
  const handleAccountChange = (account) => {
    if (account) {
      //console.log('Changed Account ID', account);
      setSelectedAccount(account);
      localStorage.removeItem('AccountID');
      localStorage.removeItem('AccountNO');
      setIsDropdownOpen(false);
      localStorage.setItem('AccountID', JSON.stringify(account.id));
      localStorage.setItem('AccountNO', JSON.stringify(account.Account_id));
      window.location.reload();
    }
  };

  const handleOrgSelect = (e) => {
    console.log(e);
    const selectedOrg = tableData.find((org) => org.company_id == e.company_id);
    setSelectedOrg(selectedOrg);
    handleOrgChange(selectedOrg);
    // Set UI states
    setIsOrgSelected(false);
    setshowcompany(false);
    setshowacc(true);
    setIsOrgDropdownOpen(false);
    // Fetch account options
    handleaccounts(e.company_id);
    setshowempdrop(true);
  };

  const [showcompany, setshowcompany] = useState(false);
  const [showacc, setshowacc] = useState(true);
  const handleAccountSelect = (account) => {
    // If the same account is clicked again, show the org dropdown
    if (selectedAccount && selectedAccount.account_id === account.account_id) {
      setIsDropdownOpen(false);
      setshowacc(true);
      setshowcompany(false); // Show the company dropdown
    } else {
      // Otherwise, select the new account and close the dropdown
      setSelectedAccount(account);
      handleSelect(account);
      setIsDropdownOpen(false); // Close the account dropdown
      setIsOrgSelected(false);
      setshowcompany(false); // Show the company dropdown
      setshowacc(true); // Hide the account dropdown
      setIsAccountDropdownOpen(false); // Close the Account dropdown
      setIsOrgDropdownOpen(false); // Keep Org dropdown closed
    }
  };

  const handleOrgDropdownToggle = () => {
    setIsOrgDropdownOpen((prev) => !prev);
    setIsAccountDropdownOpen(false); // Ensure Account dropdown is closed
  };
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
    setIsOpen(!isOpen);
  };

  const getLogo = (serviceProvider) => {
    switch (serviceProvider) {
      case 'AWS':
        return newaws;
      case 'Gcp':
        return gcp;
      case 'Azure':
        return azure;
      default:
        return newaws;
    }
  };
  useEffect(() => {
    handlenotifications();
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const notificationIcons = {
    bell: bellicon,
    alert: alert,
    normal: normal,
  };
  const transformApiDataToNotifications = (apiData) => {
    return apiData.map((item) => {
      let status = 'info';
      let icon = 'bell';
      let bgColor = 'bg-green-50';
      switch (item.severity) {
        case 'critical':
          status = 'warning';
          icon = 'alert';
          bgColor = 'bg-red-50';
          break;
        case 'normal':
          status = 'success';
          icon = 'normal';
          bgColor = 'bg-green-50';
          break;
        case 'info':
          status = 'info';
          icon = 'bell';
          bgColor = 'bg-blue-50';
          break;
        default:
          break;
      }
      return {
        id: item.notification_id,
        title: item.title,
        description: item.description,
        timestamp: moment(item.i_ts).format('HH:mm'),
        status: item.status,
        severity: item.severity,
        url: item.url,
        date: item.i_ts,
        icon: notificationIcons[icon] || notificationIcons.bell,
        bgColor,
      };
    });
  };
  const groupedNotifications = Object.entries(
    notification.reduce((groups, notification) => {
      const formattedDate = moment(notification.date).format('DD MMM YYYY'); // Format date as "14 Nov 2024"
      if (!groups[formattedDate]) {
        groups[formattedDate] = [];
      }
      groups[formattedDate].push(notification);
      return groups;
    }, {})
  ).sort(
    ([dateA], [dateB]) =>
      moment(dateB, 'DD MMM YYYY') - moment(dateA, 'DD MMM YYYY')
  );
  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  const handlenotifications = () => {
    const userinfo = localStorage.getItem('UserInfo');
    const userdata = JSON.parse(userinfo);
    //console.log(userdata);
    let id = userdata[0].id;
    const payload = {
      user_id: id.toString(),
    };
    post(NOTIFICATIONS, payload)
      .then((response) => {
        //console.log(response);
        if (response.data && response.data.data) {
          const transformedNotifications = transformApiDataToNotifications(
            response.data.data
          );
          //console.log(transformedNotifications);
          setNotification(transformedNotifications);
        } else {
          console.error('Unexpected response format:', response);
        }
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
      });
  };
  const handleredirect = (notification) => {
    //console.log(notification);
    const userinfo = localStorage.getItem('UserInfo');
    if (!userinfo) {
      console.error('User data not found!');
      return;
    }
    let userdata;
    try {
      userdata = JSON.parse(userinfo);
    } catch (error) {
      console.error('Error parsing user data:', error);
      return;
    }
    if (!userdata || userdata.length === 0 || !userdata[0].id) {
      console.error('User data is incomplete!');
      return;
    }
    //console.log(userdata);
    const id = userdata[0].id;
    const payload = {
      user_id: id.toString(),
      notification_id: notification.id.toString(),
    };
    //console.log(payload);
    post(NOTIFICATIONS_STATUS, payload)
      .then((response) => {
        //console.log(response);
        if (response.data.code === 200) {
          //console.log('ffffffffff');
          navigate(notification.url);
          handlenotifications();
        } else {
          console.error(
            'Failed to update notification status:',
            response.data.message
          );
        }
      })
      .catch((error) => {
        console.error('Error updating notification status:', error);
      });
  };

  const handleOrgChange = (account) => {
    //console.log('Selected Organization:', account);
    setSelectedAccount(null);
    if (account) {
      setselectedorgname(account.company_name);
      setSelectedAccount(account.company_name);
      handleaccounts(account.company_id);
    }
  };
  const handleAccChange = (e) => {
    console.log('selected Account', e);
    // setSelectedAccount(e)
    // Find the selected account based on the account_id
    const selected = accountOptions.find(
      (acc) => acc.account_id === e.account_id
    );
    if (selected) {
      setSelectedAccount(selected);
      // Store the selected account details in localStorage
      localStorage.setItem('AccountID', JSON.stringify(selected.accountID_id));
      localStorage.setItem('AccountNO', JSON.stringify(selected.account_id));
      localStorage.setItem(
        'SelectedAccountID',
        JSON.stringify(selected.accountID_id)
      );
      localStorage.setItem(
        'SelectedAccountNO',
        JSON.stringify(selected.account_id)
      );
      // Refresh the page to reflect changes if necessary
      window.location.reload();
    }
  };
  const handleSelect = (account) => {
    handleAccChange(account);
    setIsOpen(false);
  };
  useEffect(() => {
    const defaultOrg = tableData[0];
    const defaultAccount = accountOptions[0];
    //console.log("defaultAccount", defaultAccount)
    if (defaultAccount != undefined && parsedUserInfo.type_of_company === 2) {
      localStorage.setItem('AccountNO', defaultAccount.account_id);
      localStorage.setItem('AccountID', defaultAccount.accountID_id);
      localStorage.setItem(
        'AccountID',
        JSON.stringify(defaultAccount.account_id)
      );
      localStorage.setItem(
        'AccountNO',
        JSON.stringify(defaultAccount.accountID_id)
      );
    }

    setSelectedOrg(defaultOrg);
    // setSelectedAccount(defaultAccount);
  }, [tableData, accountOptions]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // Filtered account options
  const filteredAccountOptions = accountOptions?.filter((acc) => {
    const accountId = acc.account_id ? acc.account_id.toLowerCase() : '';
    const accountName = acc.account_name ? acc.account_name.toLowerCase() : '';
    return (
      accountId.includes(AccsearchTerm.toLowerCase()) ||
      accountName.includes(AccsearchTerm.toLowerCase())
    );
  });

  // Filtered company options
  const filteredTableData = tableData?.filter((org) => {
    const companyName = org.company_name ? org.company_name.toLowerCase() : '';
    return companyName.includes(EmpsearchTerm.toLowerCase());
  });

  const clearSearch = (type) => {
    if (type === 'account') {
      setAccSearchTerm(''); // Clear the state
    }
    if (type === 'company') {
      setEmpSearchTerm(''); // Clear the state
    }
  };
  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen((prev) => !prev);
    setIsOrgDropdownOpen(false); // Ensure Org dropdown is closed
  };
  const [sidebarState, setSidebarState] = useState(
    localStorage.getItem('sidebarState') || 'open'
  );
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const savedSidebarState = localStorage.getItem('sidebarState');
      if (savedSidebarState !== sidebarState) {
        setSidebarState(savedSidebarState);
      }
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [sidebarState]);

  const restrictedRoutes = [
    '/admin/profilesettings',
    '/admin/settings/users',
    '/admin/settings/companyInfo',
    '/admin/settings/RolesRes',
    '/admin/CloudPatrolMutelistCreate',
    '/admin/settings/pushnotifications',
    '/admin/settings/neworganization',
    '/admin/bill_summary',
    '/admin/saving_simulations',
    '/admin/settings/billconfig',
  ];

  // Check if the current route is restricted
  const isRestrictedRoute = restrictedRoutes.includes(location.pathname);

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center ${
        brandText === 'New Organization' || brandText === 'CloudPatroloveriew'
          ? 'justify-end'
          : 'justify-between'
      } rounded-xl bg-white/30 transition-all ${
        mini === false
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : mini === true && hovered === true
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]'
      } p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:rig ht-[30px] md:top-4 xl:top-[20px]`}
    >
      {brandText !== 'New Organization' &&
        brandText !== 'CloudPatroloveriew' &&
        sidebarState == 'open' && (
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">
              {brandText}
            </Link>
          </p>
        )}

      {brandText !== 'New Organization' &&
        brandText !== 'CloudPatroloveriew' &&
        sidebarState == 'close' && (
          <p className="ml-[-200px] shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">
              {brandText}
            </Link>
          </p>
        )}
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        {user.type_of_company != 2 && (
          <div
            ref={dropdownRef}
            className="relative flex h-full flex-col items-start rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]"
            style={{ height: '120%' }}
          >
            {' '}
            <div
              className="flex cursor-pointer items-center"
              onClick={toggleDropdown}
              style={{ marginLeft: '8px', marginTop: '6px' }}
            >
              {selectedAccount ? (
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '25px',
                    width: '55px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <img
                    src={getLogo(selectedAccount.Service_provider)}
                    alt={selectedAccount.Service_provider + ' Logo'}
                    style={{ width: '30px', marginTop: '5px' }}
                  />
                </div>
              ) : null}

              <div className="ml-2 flex flex-col">
                <p
                  className="text-lg"
                  style={{ fontWeight: '600', fontSize: '13px' }}
                >
                  {selectedAccount
                    ? selectedAccount.Account_id
                    : 'Select Account'}
                </p>
                <p
                  className="text-gray-500"
                  style={{ fontSize: '12px', marginTop: '-5px' }}
                >
                  {selectedAccount
                    ? selectedAccount.Account_name
                    : 'Account Name'}
                </p>
              </div>
              <img
                src={dropd}
                alt="Dropdown indicator"
                className="ml-auto h-3 w-2"
                style={{ marginLeft: '35px' }}
              />
            </div>
            {isDropdownOpen && !isRestrictedRoute && (
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  position: 'absolute',
                  top: '130%',
                  left: '0',
                  padding: '10px',
                  borderRadius: '15px',
                  zIndex: 10,
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                {parsedUserInfo[0]?.Account_information.length > 4 && (
                  <div className="relative w-full items-center sm:w-fit">
                    <input
                      type="text"
                      placeholder="Search..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="block w-full rounded-full bg-lightPrimary pl-10 pr-2 text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
                    />
                    <FiSearch className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-gray-400 dark:text-white" />{' '}
                  </div>
                )}
                {parsedUserInfo[0]?.Account_information?.map(
                  (account, index) => {
                    // //console.log('account', account);
                    let logo;
                    switch (account.Service_provider) {
                      case 'AWS':
                        logo = newaws;
                        break;
                      case 'Gcp':
                        logo = gcp;
                        break;
                      case 'Azure':
                        logo = azure;
                        break;
                      default:
                        logo = newaws;
                    }
                    return (
                      <div
                        onClick={() => handleAccountChange(account)}
                        key={account.id}
                        className={`flex w-full cursor-pointer items-center p-4 hover:bg-gray-100 ${
                          index !==
                          parsedUserInfo[0].Account_information.length - 1
                            ? 'border-b'
                            : ''
                        }`}
                      >
                        <div className="  flex h-auto w-auto items-center justify-center rounded-lg bg-[#F4F7FE] object-fill sm:h-[1.2rem] sm:w-[100%]">
                          <img
                            src={logo}
                            alt={`${account.Service_provider} Logo`}
                            className="h-[100%] w-[100%] object-fill sm:h-[1.2rem] sm:w-[100%]"
                          />
                        </div>
                        <div className="ml-4 flex-grow">
                          <p className="text-lg text-sm font-semibold">
                            {account.Account_id}
                          </p>
                          <p className="text-sm text-gray-500">
                            {account.Account_name}
                          </p>
                        </div>
                        <div className="ml-auto">
                          {account.Service_provider === 'AWS' ? (
                            <span className="h-4 w-4 rounded-full bg-green-500" />
                          ) : (
                            <span className="h-4 w-4 rounded-full bg-gray-300" />
                          )}
                        </div>
                        <input
                          id="green-radio"
                          type="radio"
                          value=""
                          name="colored-radio"
                          checked={selectedAccount?.id === account.id}
                          className="ml-3 h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2 focus:ring-[#02AAE2] dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800"
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        )}
        {user.type_of_company === 2 && (
          <div
            ref={dropdownRef}
            className="relative flex h-full flex-col items-start text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]"
            style={{ height: 'auto' }}
          >
            {accountOptions?.length > 0 && !isOrgSelected && showacc && (
              <div
                className="flex cursor-pointer items-center"
                onClick={handleOrgDropdownToggle}
                style={{ margin: '10px' }}
              >
                {selectedAccount ? (
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '25px',
                      width: '55px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <img
                      src={getLogo(selectedAccount.Service_provider)}
                      alt={selectedAccount.Service_provider + ' Logo'}
                      style={{ width: '30px', marginTop: '5px' }}
                    />
                  </div>
                ) : null}
                <div className="ml-2 flex flex-col">
                  <p
                    className="text-lg"
                    style={{ fontWeight: '600', fontSize: '13px' }}
                  >
                    {selectedAccount
                      ? selectedAccount.account_id
                      : 'Select Account'}
                  </p>
                  <p
                    className="text-gray-500"
                    style={{ fontSize: '12px', marginTop: '-5px' }}
                  >
                    {selectedAccount
                      ? selectedAccount.account_name
                      : 'Account Name'}
                  </p>
                </div>
                <img
                  src={dropd}
                  alt="Dropdown indicator"
                  className="ml-auto h-3 w-2"
                  style={{ marginLeft: '35px' }}
                />
              </div>
            )}
            {isAccountDropdownOpen &&
              accountOptions?.length > 0 &&
              showempdrop &&
              !isRestrictedRoute && (
                <div
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    position: 'absolute',
                    top: '130%',
                    left: '0',
                    padding: '10px',
                    borderRadius: '15px',
                    zIndex: 10,
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {accountOptions.length > 4 && (
                    <div className="relative w-full items-center sm:w-fit">
                      <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setAccSearchTerm(e.target.value)}
                        value={AccsearchTerm}
                        className="block w-full rounded-full bg-lightPrimary pl-10 pr-2 text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
                      />
                      <FiSearch className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-gray-400 dark:text-white" />{' '}
                      {AccsearchTerm && (
                        <FiX
                          className="absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 transform cursor-pointer text-gray-400 dark:text-white"
                          onClick={() => clearSearch('account')}
                        />
                      )}
                    </div>
                  )}
                  <div
                    style={
                      filteredAccountOptions.length > 5
                        ? { maxHeight: '200px', overflow: 'auto', top: '8px' }
                        : {}
                    }
                  >
                    {filteredAccountOptions?.map((acc, index) => {
                      let logo;
                      switch (acc.Service_provider) {
                        case 'AWS':
                          logo = newaws;
                          break;
                        case 'Gcp':
                          logo = gcp;
                          break;
                        case 'Azure':
                          logo = azure;
                          break;
                        default:
                          logo = newaws;
                      }
                      return (
                        <div
                          key={acc.account_id}
                          className={`flex w-full cursor-pointer items-center p-4 hover:bg-gray-100 ${
                            index !== filteredAccountOptions.length - 1
                              ? 'border-b'
                              : ''
                          }`}
                          onClick={() => handleAccountSelect(acc)}
                        >
                          <div className=" flex-grow">
                            <img
                              src={logo}
                              alt={`${acc.service_provider} Logo`}
                              className="mr-3 h-[1.2rem] w-[1.2rem] object-contain"
                            />
                            <p className="text-sm text-gray-500">
                              {acc.account_id}
                            </p>
                            <p className="text-sm text-gray-500">
                              {acc.account_name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            {showcompany && (
              <div
                className="flex cursor-pointer items-center"
                onClick={toggleAccountDropdown}
                style={{ margin: '10px' }}
              >
                <div className="ml-2 flex flex-col">
                  <p
                    className="text-gray-500"
                    style={{ fontSize: '12px', marginTop: '-5px' }}
                  >
                    {selectedOrg ? selectedOrg.company_name : 'Select Company'}
                  </p>
                </div>
                <img
                  src={dropd}
                  alt="Dropdown indicator"
                  className="ml-auto h-3 w-2"
                  style={{ marginLeft: '35px' }}
                />
              </div>
            )}
            {isOrgDropdownOpen && !isRestrictedRoute && (
              <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  position: 'absolute',
                  top: '130%',
                  left: '0',
                  padding: '10px',
                  borderRadius: '15px',
                  zIndex: 10,
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                {tableData.length > 4 && (
                  <div className="relative w-full items-center sm:w-fit">
                    <input
                      type="text"
                      placeholder="Search..."
                      onChange={(e) => setEmpSearchTerm(e.target.value)}
                      value={EmpsearchTerm}
                      className="block w-full rounded-full bg-lightPrimary pl-10 pr-2 text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
                    />
                    <FiSearch className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 transform text-gray-400 dark:text-white" />{' '}
                    {EmpsearchTerm && (
                      <FiX
                        className="absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 transform cursor-pointer text-gray-400 dark:text-white"
                        onClick={() => clearSearch('company')}
                      />
                    )}
                  </div>
                )}
                <div
                  style={
                    filteredTableData.length > 5
                      ? { maxHeight: '200px', overflow: 'auto' }
                      : {}
                  }
                >
                  {filteredTableData?.map((org, index) => {
                    return (
                      <div
                        key={org.company_id}
                        className={`flex w-full cursor-pointer items-center p-4 hover:bg-gray-100 ${
                          index !== filteredTableData.length - 1
                            ? 'border-b'
                            : ''
                        }`}
                        onClick={() => handleOrgSelect(org)}
                      >
                        <div className="flex-grow">
                          <p className="text-sm text-gray-500">
                            {org.company_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <Suspense fallback={<div>Loading components...</div>}>

        <Dropdown
          button={
            <div className="relative mr-2">
              <p className="cursor-pointer">
                <IoMdNotificationsOutline className="h-6 w-6 cursor-pointer text-gray-600 dark:text-white" />
              </p>
              {notification.filter((n) => n.status === 0).length > 0 && (
                <span className="absolute -right-2 -top-1 rounded-full bg-red-500 px-1.5 py-0.5 text-xs font-bold text-white">
                  {notification.filter((n) => n.status === 0).length}
                </span>
              )}
            </div>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="mx-auto flex h-[500px] w-[550px] flex-col rounded-xl bg-white p-6 shadow-lg 2xl:w-[550px] 3xl:w-[550px]">
              <div className="mb-4 flex items-center justify-between">
                <h2 className="text-lg font-semibold text-gray-900">
                  Notifications
                </h2>
                <button className="rounded-xl bg-[#02AAE2] px-5 py-2.5 text-white">
                  Unread ({notification.filter((n) => n.status === 0).length})
                </button>
              </div>
              <div className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent hover:scrollbar-thumb-gray-400 flex-1 overflow-y-auto pr-4">
                {groupedNotifications.map(([date, notifications]) => (
                  <div key={date}>
                    <h3 className="sticky top-0 z-10 mb-2 bg-white py-2 text-sm font-medium text-gray-900">
                      {date}
                    </h3>
                    {notifications.map((notification) => (
                      <div
                        key={notification.id}
                        onClick={() => handleredirect(notification)}
                        className="flex cursor-pointer items-start rounded-lg p-4 transition-colors duration-200 hover:bg-gray-50"
                      >
                        <div className="relative">
                          <div
                            className={`flex h-10 w-10 items-center justify-center rounded-full ${notification.bgColor}`}
                          >
                            <img
                              src={notification.icon}
                              className="h-2/4 w-2/4 object-contain"
                              alt="notification icon"
                            />
                          </div>
                          {notification.status === 0 && (
                            <div
                              className={`absolute -top-0 right-1 h-2 w-2 rounded-full ${
                                notification.severity === 'critical'
                                  ? 'bg-red-500'
                                  : 'bg-green-500'
                              } ring-2 ring-white`}
                            />
                          )}
                        </div>
                        <div className="ml-4 flex-grow">
                          <div className="flex items-start justify-between">
                            <h3 className="text-[18px] font-medium capitalize text-gray-900">
                              {notification.title}
                            </h3>
                            <div className="flex items-center text-sm text-gray-500">
                              {notification.timestamp}
                            </div>
                          </div>
                          <p className="mt-1 text-sm text-gray-600">
                            {notification.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          }
          classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
        />
        </Suspense>
        <p className="cursor-pointer">
          <Link
            to="/admin/settings"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            <IoMdSettings className="h-4 w-4 text-gray-600 dark:text-white" />
          </Link>
        </p>
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        ></div>
              <Suspense fallback={<div>Loading components...</div>}>
        <Dropdown
          button={
            profile ? (
              <img
                className="h-10 w-10 rounded-full object-contain"
                src={profile}
                alt={`${user.f_name} ${user.l_name}`}
              />
            ) : (
              <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-[#02AAE2] p-6 text-xl font-bold text-white">
                {user.f_name?.charAt(0).toUpperCase()}
                {user.l_name?.charAt(0).toUpperCase()}
              </div>
            )
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  {parsedUserInfo.length > 0 ? (
                    <p>
                      👋 Hey, {parsedUserInfo[0].f_name}{' '}
                      {parsedUserInfo[0].l_name}
                    </p>
                  ) : (
                    <p>👋 Hey, Guest</p>
                  )}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />
              <div className="ml-4 mt-3 flex flex-col">
                <a
                  onClick={handleprofile}
                  className="cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  onClick={logout}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
        </Suspense>
      </div>
    </nav>
  );
};
export default React.memo(Navbar);
