import React, { useState, useEffect, Suspense } from 'react';
import { HiX, HiArrowCircleLeft, HiArrowCircleRight } from 'react-icons/hi';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import routes from 'routes';
import celogo from 'assets/img/logo.webp';
import celogo2 from 'assets/img/celogo2.webp';
import { useNavigate } from 'react-router-dom';
import ce from 'assets/img/auth/ce.webp';
const Links = React.lazy(() => import('./components/Links'));
const IconLinks = React.lazy(() => import('./components/iconLincks'));
const SidebarCard = React.lazy(() => import('components/sidebar/components/SidebarCard'));
const Card = React.lazy(() => import('components/card'));
function SidebarHorizon(props) {
  const { open, onClose, variant } = props;

  const [mini, setMini] = useState(true); // Default to collapsed
  const [sidebarOpen, setSidebarOpen] = useState(open); // State to manage sidebar visibility

  const userdetails = localStorage.getItem('UserDetails');
  const userinfo = localStorage.getItem('UserInfo');
  let onboard = '';
  let CompanyType = '';
  if (userdetails) {
    const parsedUserDetails = JSON.parse(userdetails);
    onboard = parsedUserDetails?.onboard || '';
  }
  if (userinfo) {
    const parsedUserInfo = JSON.parse(userinfo);
    CompanyType = parsedUserInfo[0]?.type_of_company || '';
  }
  const history = useNavigate();
  const modifiedRoutes = routes
    .map((route) => {
      if (route.name === 'Push Notifications' && CompanyType === 1) {
        return false;
      }
      if (route.name === 'Authentication' && route.items) {
        const filteredSubRoutes = route.items.filter((subRoute) => {
          if (CompanyType === 1) {
            return (
              subRoute.name !== 'New Organization' &&
              subRoute.name !== 'Push Notifications'
            );
          }
          if (CompanyType === 2) {
            return subRoute.name !== 'Health Check';
          }
          return true;
        });
        return { ...route, items: filteredSubRoutes };
      }
      if (
        onboard === 'NO' &&
        (route.name === 'Tag Orbit' || route.name === 'Cloud Patrol')
      ) {
        return { ...route, disabled: true };
      }
      if (CompanyType === 1 && route.name === 'Saving Simulations') {
        return false;
      }
      return route;
    })
    .filter(Boolean);

  const handleContactAdminClick = () => {
    history('/contactadmin');
  };
  useEffect(() => {
    const storedState = localStorage.getItem('sidebarState');
    if (storedState === 'open') {
      setMini(false); // Open if localStorage indicates it
    } else {
      setMini(true); // Default to closed if no state or "close" is found
    }
  }, []);
  const toggleSidebar = () => {
    const newState = mini ? 'open' : 'close';
    localStorage.setItem('sidebarState', newState);
    setMini(!mini);
  };
  return (
    <div className="relative">
      <div
        className={`sm:none ${sidebarOpen ? '' : '-translate-x-[105%]'} ${
          mini === false ? 'w-[285px]' : 'w-[100px] overflow-x-hidden'
        } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
          variant === 'auth' ? 'xl:hidden' : 'xl:block'
        }`}
      >
                        <Suspense fallback={<div>Loading...</div>}>
        <Card extra="ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]">
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={mini ? renderViewMini : renderView}
          >
            <div className="flex h-full flex-col justify-between">
              <div>
                <span
                  className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                  onClick={onClose}
                >
                  <HiX />
                </span>
                <button
                  className="absolute left-[calc(100%-30px)] top-1 z-50 -translate-x-1/2 transform rounded-full bg-green-500 bg-white p-2 shadow-lg"
                  onClick={toggleSidebar}
                >
                  {mini ? (
                    <HiArrowCircleRight className="text-2xl" />
                  ) : (
                    <HiArrowCircleLeft className="text-2xl" />
                  )}
                </button>
                <div className="ml-[10px] mr-[10px] mt-[50px] flex items-center justify-between ">
                  <div className="flex items-center">
                    <img
                      src={mini ? ce : celogo}
                      className="font-medium dark:hidden"
                      alt="Logo"
                    />
                    <img
                      src={mini ? ce : celogo2}
                      className="hidden font-medium dark:block"
                      alt="Logo Dark"
                    />
                  </div>
                </div>
                <div className="mb-7 mt-[58px] h-px bg-gray-200 dark:bg-white/10" />
                <Suspense fallback={<div>Loading...</div>}>
                {mini ? (
                  <ul>
                    <IconLinks
                      mini={mini}
                      hovered={!mini}
                      routes={modifiedRoutes}
                    />
                  </ul>
                ) : (
                  <ul>
                    <Links
                      mini={mini}
                      hovered={!mini}
                      routes={modifiedRoutes}
                    />
                  </ul>
                )}
                </Suspense>
              </div>

              {!mini && CompanyType !== 2 && (
                <div className="my-8">
                  <div className="flex justify-center">
                  <Suspense fallback={<div>Loading...</div>}>
                    <SidebarCard mini={mini} hovered={!mini} />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
          </Scrollbars>
        </Card>
        </Suspense>
      </div>
    </div>
  );
}

export default React.memo(SidebarHorizon);
