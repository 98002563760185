exports.Token_payload_Access = async (token)=>{
    const parts = token.split('.');
   
    if (parts.length !== 3) {
        throw new Error('Invalid JWT token');
    }

    const payload = parts[1];
    
    // Base64-decode the payload
    const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')));

    return decodedPayload;
}