export const BASE_URL = 'https://api.cloudevolve.com/';
export const ACCOUNT_ID = '062048322215';
export const LoginAPI = BASE_URL + 'login';
export const SignupAPI = BASE_URL + 'signup';
export const GoogleAPI = BASE_URL + 'google_sign_in_or_sign_up';
export const MicrosoftAPI = BASE_URL + 'microsoft_sign_in_or_sign_up';
export const GithubAPI = BASE_URL + 'github_sign_in_or_sign_up';
export const DropDownAPi = BASE_URL + 'prowler_getDropdownData';
export const findTableApi = BASE_URL + 'finding';
export const GetCardsAPi = BASE_URL + 'Tag_Manager_Dashboard_counts';
export const HealthGetCardsAPi = BASE_URL + 'Tags_health_score_Report';
export const LandscapeGetCardsAPi = BASE_URL + 'Tags_health_score_Report';
export const FusionGetCardsAPi = BASE_URL + 'Tag_consolidation_name_data';
export const SPENDINSIGHTS = BASE_URL + 'cost_explorer_form';
export const GETDIMENSIONS = BASE_URL + 'get_dimensions_dropdown';
export const GETDRESOURCETYPE = BASE_URL + 'get_resource_type';
//Cloudpatrol
export const DONUT = BASE_URL + 'status_analytics';
export const AWS = BASE_URL + 'status_aws_account ';
export const SEVERITY = BASE_URL + 'status_serverity ';
export const SECURITY_POSTURE = BASE_URL + 'security_posture';
export const CLOUDPATROL_HISTORY = BASE_URL + 'prowler_history';
export const CLOUDPATROL_TABLE = BASE_URL + 'finding';
export const DATE_FAIL_PASS_COUNT = BASE_URL + 'prowler_getProwlerDateData';
export const YAML_MUTE_LIST_CREATE =
  BASE_URL + 'prowler_prowler_mute_list_yaml_file_create';
//Cloudpatrol Compilance
export const CLOUDPATROL_COMPILANCE = BASE_URL + 'prowler_compilance';
//Cloudpatrol Service
export const CLOUDPATROL_SERVICE = BASE_URL + 'prowler_service';
//CloudPatrol Region
export const FETCHREGIONS = BASE_URL + 'region_analytics';
// Tag Manager
export const get_tag_Resources_group = BASE_URL + 'get_tag_Resources_group';
export const get_tag_key_dropdown = BASE_URL + 'get_tag_key_dropdown';
export const get_tag_key_dropdown_filter =
  BASE_URL + 'get_tag_key_dropdown_filter';
export const Tag_expoler_tag_creation = BASE_URL + 'Tag_expoler_tag_creation';
export const cost_Allocation_Activation =
  BASE_URL + 'cost_Allocation_Activation';
export const Tags_refresh_from_Aws = BASE_URL + 'Tags_refresh_from_Aws';
export const delete_tag_for_particular_resources =
  BASE_URL + 'delete_tag_for_particular_resources';
export const Edit_single_tag_for_arn = BASE_URL + 'Edit_single_tag_for_arn';
export const Edit_bulk_tags = BASE_URL + 'Edit_bulk_tags';
export const UpdateTagValueForResources =
  BASE_URL + 'UpdateTagValueForResources';
export const getAllResourcenameavailableforTag =  BASE_URL + 'getAllResourcenameavailableforTag';
export const getAllTagsAndResourceswithResourceName =  BASE_URL + 'getAllTagsAndResourceswithResourceName';

export const NEWSIGNUP = BASE_URL + 'new_Sign_up';
export const GETROLES = BASE_URL + 'get_roles';
export const GETUSERDETAILS = BASE_URL + 'get_user_details';
export const ADMINUSERDATA = BASE_URL + 'Admin_gets_userdata_in_local_db';
export const ADMINUPDATEUSERDATA =
  BASE_URL + 'Admin_update_userdata_in_local_db';
export const CREATEUSER = BASE_URL + 'admin_role_creating_users';

//Roles
export const get_admin_created_roles = BASE_URL + 'get_admin_created_roles';
export const get_available_routes_for_role =
  BASE_URL + 'get_available_routes_for_role';
export const get_Accounts_ids = BASE_URL + 'get_Accounts_ids';
export const admin_creating_roles = BASE_URL + 'admin_creating_roles';
export const admin_update_roles = BASE_URL + 'admin_update_roles';
export const admin_role_Activate_and_deactivate =
  BASE_URL + 'admin_role_Activate_and_deactivate';

export const USERSBYCOMPANY = BASE_URL + 'get_company_extn_users';
export const UPDATEUSERSBYCOMPANY = BASE_URL + 'update_user_to_company';

export const SEND_DETAILS = BASE_URL + 'create_company_profile';
export const CHANGE_PASSWORD = BASE_URL + 'user_change_password';

export const USER_DETAILS_CHANGE =
  BASE_URL + 'Admin_update_userdata_in_local_db';

export const USER_FORGOT_PASSWORD = BASE_URL + 'user_forgot_password';

//Companyinfo
export const COMPANYINFO = BASE_URL + 'get_company_profile_Details';
export const UPDATECOMPANYINFO = BASE_URL + 'update_company_profile_Details';
export const UPDATECOMPANYLOGO = BASE_URL + 'company_logo_upload';

export const LOGOUT = BASE_URL + 'logout';

export const PROFIC_PIC = BASE_URL + 'user_profile_pic_upload';
export const GETBILLSUMMARY = BASE_URL + 'get_bill_summary';

export const NOTIFICATIONS = BASE_URL + 'news_notification';

export const NOTIFICATIONS_STATUS =
  BASE_URL + 'news_notification_status_change';

export const CONTACT_US = BASE_URL + 'contact_us_enquiry';
export const EC2_On_Demand_Spend_Region_Wise =
  BASE_URL + 'EC2_On_Demand_Spend_Region_Wise';
export const EC2_On_Demand_Spend_Instance_Family_Wise =
  BASE_URL + 'EC2_On_Demand_Spend_Instance_Family_Wise';
export const EC2_On_Demand_Spend_Operating_System_Wise =
  BASE_URL + 'EC2_On_Demand_Spend_Operating_System_Wise';
export const EC2_On_Demand_Spend_on_spot_Usage =
  BASE_URL + 'EC2_On_Demand_Spend_on_spot_Usage';
export const EC2_On_Demand_Spend_on_Trend =
  BASE_URL + 'EC2_On_Demand_Spend_on_Trend';
export const Lambda_Usage_Cost_by_ID_Per_Hour =
  BASE_URL + 'Lambda_Usage_Cost_by_ID_Per_Hour';
export const EC2_On_Demand_Spend_Instance_Family_Wise_Stacked_Bar_graph =
  BASE_URL + 'EC2_On_Demand_Spend_Instance_Family_Wise_Stacked_Bar_graph';
export const EC2_Spend_on_spot_Usage_VS_On_demand =
  BASE_URL + 'EC2_Spend_on_spot_Usage_VS_On_demand';
export const Tag_resource_details = BASE_URL + 'Tag_resource_details';
export const Regional_Data_Transfer_Cost_or_InterAZ_Data_transfer_cost =
  BASE_URL + 'Regional_Data_Transfer_Cost_or_InterAZ_Data_transfer_cost';
export const Data_Transfer_cost_by_service =
  BASE_URL + 'Data_Transfer_cost_by_service';

export const HEALTH_CHECK = BASE_URL + 'Tag_Health_Status_Report';

export const ACCOUNT_INFO = BASE_URL + 'get_Account_info';

export const ROLE_BY_ORG = BASE_URL + 'role_id_based_on_account_id';

export const USR_ROLE_BY_ORG = BASE_URL + 'user_id_based_on_account_id_role_id';

export const URL = BASE_URL + 'front_end_url_end_point';

export const PUSH_FILE_UP = BASE_URL + 'adding_notification_image_to_S3';

export const SENDING_PUSH = BASE_URL + 'publish_notifications';

export const END_POINT = BASE_URL + 'endpoint_create_using_fcm_token';

export const PUSH_HISTORY = BASE_URL + 'push_notification_history';
export const S3_Data_Analysis = BASE_URL + 'S3_Data_Analysis';
export const S3_Data_Analysis_Datewise = BASE_URL + 'S3_Data_Analysis_Datewise';
export const S3_Data_Analysis_Region_Wise_in_TB =
  BASE_URL + 'S3_Data_Analysis_Region_Wise_in_TB';
export const S3_Data_Analysis_Region_and_Maser_Account_Wise_in_TB =
  BASE_URL + 'S3_Data_Analysis_Region_and_Maser_Account_Wise_in_TB';
export const Billing_Sample_S3_transfer =
  BASE_URL + 'Billing_Sample_S3_transfer';
export const Billing_Sample_S3_storage = BASE_URL + 'Billing_Sample_S3_storage';



export const ORGANIZATION = BASE_URL + 'get_organizations';

export const GET_ORG_COMP_ID = BASE_URL + 'get_organizations_by_company_id';

export const ORG_STATUS_UPD = BASE_URL + 'Employee_update_organization_status';


export const GET_USER_DET = BASE_URL + 'Employee_get_users_profile_by_company_id';



export const UPD_ORG = BASE_URL + 'Employee_update_organization_profile_Details';


export const GET_ACCOUNTS = BASE_URL + 'Employee_get_Account_id_profile';

export const EMP_GET_ACC_COMP_ID = BASE_URL + 'Employee_get_Account_id_profile_by_company';


export const EMP_ACC_UPDATE = BASE_URL + 'Employee_update_Account_id_profile';



export const USR_ACC_UPDATE = BASE_URL + 'Employee_update_userdata_in_local_db';




export const CREATE_ORGANIZATION = BASE_URL + 'Employee_New_organization_creation';


export const CREATE_ACCOUNT_PROFILE = BASE_URL + 'Employee_create_Account_id_profile';


export const CREATE_EMLOYEE_USER = BASE_URL + 'Employee_role_creating_users';
export const Total_S3_Spend_Analysis_Stacked_with_Region =
  BASE_URL + 'Total_S3_Spend_Analysis_Stacked_with_Region';
//getting fcm configuration from the backend 
export const FCMCONFIG = BASE_URL + 'fcm_config';
export const get_simulation_accounts = BASE_URL + 'get_simulation_accounts';
export const get_ri_data = BASE_URL + 'get_ri_data';
export const get_services_data_dropdown = BASE_URL + 'get_services_data_dropdown';
export const get_regions_data_dropdown = BASE_URL + 'get_regions_data_dropdown';
export const get_operating_systems_data_dropdown = BASE_URL + 'get_operating_systems_data_dropdown';
export const new_aws_billing_config_details = BASE_URL + 'new_aws_billing_config_details';
export const get_aws_billing_config_details = BASE_URL + 'get_aws_billing_config_details';
export const update_aws_billing_config_details = BASE_URL + 'update_aws_billing_config_details';
export const get_Instance_family_with_data_dropdown = BASE_URL + 'get_Instance_family_with_data_dropdown';
export const get_aws_discount_types_dropdowns = BASE_URL + 'get_aws_discount_types_dropdowns';
export const get_master_accounts = BASE_URL + 'get_master_accounts';
export const get_aws_billing_config_details_by_company_id = BASE_URL + 'get_aws_billing_config_details_by_company_id';
export const get_aws_services_on_discount_id_Dependency = BASE_URL + 'get_aws_services_on_discount_id_Dependency';
export const Monthly_Trends_on_Spend = BASE_URL + 'Monthly_Trends_on_Spend';
export const Total_Unique_Services = BASE_URL + 'Total_Unique_Services';
export const Total_Accounts = BASE_URL + 'Total_Accounts';
export const Product_Wise_Spend_Previous_Month = BASE_URL + 'Product_Wise_Spend_Previous_Month';
export const Spend_Comparision_MTD = BASE_URL + 'Spend_Comparision_MTD';
export const Popular_Region_By_Spend = BASE_URL + 'Popular_Region_By_Spend';
export const Sparline_Comparison = BASE_URL + 'Sparline_Comparison';
export const Spend_By_Hour = BASE_URL + 'Spend_By_Hour';
export const Spend_By_weekday = BASE_URL + 'Spend_By_weekday';
export const Spend_By_Hour_box = BASE_URL + 'Spend_By_Hour_box';