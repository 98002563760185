import React, { createContext, useEffect, useState } from 'react';
import { Token_payload_Access } from './DecodeTokenPayload';

const ScreenLockContext = createContext();

// Internal component that handles the element disabling logic
const ScreenLockManager = ({ children }) => {
  const [disabledClasses, setDisabledClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeDisabledClasses = async () => {
      try {
        const userDetails = localStorage.getItem('UserDetails');
        if (!userDetails) {
          setDisabledClasses([]);
          setIsLoading(false);
          return;
        }

        const parsedUserDetails = JSON.parse(userDetails);
        const idToken = parsedUserDetails.idToken;
        const decodedToken = await Token_payload_Access(idToken);
        
        const blockedList = decodedToken.set_custom_payload.results[0].Blocked_list || [];
        setDisabledClasses(blockedList);
      } catch (error) {
        console.error('Error initializing disabled classes:', error);
        setDisabledClasses([]);
      } finally {
        setIsLoading(false);
      }
    };

    initializeDisabledClasses();
  }, []);

  useEffect(() => {
    if (isLoading) return;

    const disableElements = () => {
      // Function to apply disable styles to an element
      const applyDisableStyles = (element) => {
        const classArray = Array.from(element.classList);
        const shouldDisable = classArray.some(className => 
          disabledClasses.includes(className)
        );
        
        if (shouldDisable) {
          // Common styles and attributes for all elements
          element.style.pointerEvents = 'none';
          element.style.opacity = '0.5';
          element.style.cursor = 'not-allowed';
          element.setAttribute('aria-disabled', 'true');
          
          if (!element.hasAttribute('data-original-tabindex')) {
            element.setAttribute('data-original-tabindex', element.tabIndex || '0');
          }
          element.tabIndex = -1;

          // Additional handling for buttons
          if (element.tagName.toLowerCase() === 'button') {
            element.disabled = true;
            if (!element.hasAttribute('data-original-disabled')) {
              element.setAttribute('data-original-disabled', element.disabled || false);
            }
          }
        }
      };

      // Function to remove disable styles from an element
      const removeDisableStyles = (element) => {
        const classArray = Array.from(element.classList);
        const wasDisabled = classArray.some(className => 
          disabledClasses.includes(className)
        );
        
        if (wasDisabled) {
          element.style.pointerEvents = '';
          element.style.opacity = '';
          element.style.cursor = '';
          element.removeAttribute('aria-disabled');
          
          const originalTabIndex = element.getAttribute('data-original-tabindex');
          if (originalTabIndex) {
            element.tabIndex = originalTabIndex;
            element.removeAttribute('data-original-tabindex');
          }

          // Additional cleanup for buttons
          if (element.tagName.toLowerCase() === 'button') {
            const originalDisabled = element.getAttribute('data-original-disabled');
            if (originalDisabled !== null) {
              element.disabled = originalDisabled === 'true';
              element.removeAttribute('data-original-disabled');
            }
          }
        }
      };

      // Get all divs and buttons
      const divElements = document.getElementsByTagName('div');
      const buttonElements = document.getElementsByTagName('button');
      
      // Apply styles to all elements
      Array.from(divElements).forEach(applyDisableStyles);
      Array.from(buttonElements).forEach(applyDisableStyles);

      // Return cleanup function
      return () => {
        Array.from(divElements).forEach(removeDisableStyles);
        Array.from(buttonElements).forEach(removeDisableStyles);
      };
    };

    // Initial run
    const cleanup = disableElements();

    // Set up MutationObserver to watch for new elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          disableElements();
        }
      });
    });

    // Start observing the document with the configured parameters
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Cleanup function
    return () => {
      observer.disconnect();
      cleanup && cleanup();
    };
  }, [disabledClasses, isLoading]);

  return children;
};

// Main provider component that wraps everything
export const ScreenLockProvider = ({ children }) => {
  return (
    <ScreenLockContext.Provider value={null}>
      <ScreenLockManager>
        {children}
      </ScreenLockManager>
    </ScreenLockContext.Provider>
  );
};