import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import RTLLayout from 'layouts/rtl';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import 'assets/css/Plugins.css';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { ScreenLockProvider } from 'contexts/ScreenLockHook';

ReactGA.initialize('G-GN837C8WEC');

const Analytics = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
  return null;
};

// Wrapper component for protected routes that need screen lock
const ProtectedRoute = ({ children }) => {
  const userdetails = localStorage.getItem('UserDetails');
  const accessToken = userdetails ? JSON.parse(userdetails)?.accessToken : null;

  if (!accessToken) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  return <ScreenLockProvider>{children}</ScreenLockProvider>;
};

const App = () => {
  const userdetails = localStorage.getItem('UserDetails');
  const savedPath = localStorage.getItem('URL');
  let accessToken = null;

  if (userdetails) {
    const parsedUserDetails = JSON.parse(userdetails);
    accessToken = parsedUserDetails?.accessToken || null;
  }

  const [themeApp, setThemeApp] = useState({
    '--background-100': '#FFFFFF',
    '--background-900': '#070f2e',
    '--shadow-100': 'rgba(112, 144, 176, 0.08)',
    '--color-50': '#E9E3FF',
    '--color-100': '#C0B8FE',
    '--color-200': '#A195FD',
    '--color-300': '#8171FC',
    '--color-400': '#7551FF',
    '--color-500': '#02AAE2',
    '--color-600': '#3311DB',
    '--color-700': '#2111A5',
    '--color-800': '#190793',
    '--color-900': '#11047A',
  });

  const [mini, setMini] = useState(false);
  const [redirectToDefault] = useState(false);

  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
  }, [themeApp]);
 useEffect(() => {
    const storedState = localStorage.getItem('sidebarState');
    console.log("storedState",storedState)
    if (storedState === null || storedState === undefined) {
      localStorage.setItem('sidebarState', 'close');
    }  
  }, []);
  return (
    <div>
      <Analytics />
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <ProtectedRoute>
              <AdminLayout
                setMini={setMini}
                mini={mini}
                theme={themeApp}
                setTheme={setThemeApp}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="rtl/*"
          element={
            <ProtectedRoute>
              <RTLLayout
                setMini={setMini}
                mini={mini}
                theme={themeApp}
                setTheme={setThemeApp}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            accessToken ? (
              <Navigate to={savedPath || '/admin/dashboards/default'} replace />
            ) : (
              <Navigate to="/auth/sign-in" replace />
            )
          }
        />
        <Route
          path="*"
          element={
            <Navigate to={redirectToDefault ? '/auth/sign-in' : '/'} replace />
          }
        />
      </Routes>
    </div>
  );
};

export default App;