import ReactDOM from "react-dom/client";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import "./index.css";

import App from "./App";
import { DarkModeProvider } from './contexts/DarkModeContext';
import FindingsDropdown from "views/admin/SpendInsight/findingsDropdown";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
     
    <DarkModeProvider>
    <Routes>
           <Route path='/reCall' Component={FindingsDropdown} />
         </Routes>
      <App />
    </DarkModeProvider>
  </BrowserRouter>
);
