import React, { useState, useRef, useEffect } from 'react';
import { useDarkMode } from 'contexts/DarkModeContext';

const Chip = ({ label, onRemove }) => (
  <div
    className="mr-2 flex items-center rounded-full bg-gray-200 px-2 py-1"
    style={{ background: '#1AD59824' }}
  >
    <span className="text-sm">{label}</span>
    <button onClick={onRemove} className="ml-2 text-red-500">
      &times;
    </button>
  </div>
);

const FindingsDropdown = ({
  label,
  options,
  placeholder,
  onChange,
  value,
  className,
  onDropdownOpen,
  isLoading,
  hasNoData,
  multipleoptions,
  reset,
  neworganizationselectedoptions,
  showselectall,
  isReadonly,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { discovery } = useDarkMode();
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (
      label === 'Service' ||
      label === 'Compliance' ||
      label === 'Region' ||
      label === 'os' ||
      label === 'instance'
    ) {
      return Array.isArray(value) ? value : value ? [value] : [];
    }
    return [];
  });

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
    }

    if (
      neworganizationselectedoptions &&
      neworganizationselectedoptions.length > 0
    ) {
      setSelectedOptions(neworganizationselectedoptions);
    }
  }, [reset, neworganizationselectedoptions]);
  const filteredOptions = options.filter(
    (option) =>
      option.label &&
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (option) => {
    if (isReadonly) {
      console.log('Dropdown is readonly');
      return;
    }
    if (multipleoptions === 'NO') {
      const newSelection = selectedOptions.includes(option.label)
        ? []
        : [option.label];
      setSelectedOptions(newSelection);
      onChange(newSelection);
      setIsOpen(false);
    } else {
      const updatedSelections = selectedOptions.includes(option.label)
        ? selectedOptions.filter((selected) => selected !== option.label)
        : [...selectedOptions, option.label];

      setSelectedOptions(updatedSelections);
      onChange(updatedSelections);
    }
  };

  const handleRemoveChip = (option) => {
    const updatedSelections = selectedOptions.filter(
      (selected) => selected !== option
    );
    setSelectedOptions(updatedSelections);
    onChange(updatedSelections);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (!discovery) {
      setSelectedOptions([]);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [discovery, dropdownRef]);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen && onDropdownOpen) {
      onDropdownOpen();
    }
  };

  const handleSelectAll = () => {
    const allOptions = options.map((option) => option.label);
    if (selectedOptions.length !== allOptions.length) {
      setSelectedOptions(allOptions);
      onChange([...allOptions]); // Sync with parent
    }
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
    localStorage.removeItem('selectedOptions');
  };
  useEffect(() => {
    if (label === 'RegionNew') {
      handleSelectAll();
    }
  }, [label, options]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={handleDropdownClick}
        className="relative w-full cursor-pointer rounded-xl border border-gray-200 p-3 text-sm font-normal text-gray-600 dark:border-white/10 dark:bg-gray-800 dark:text-white"
      >
        {/* Display selected options as chips */}
        <div className="flex flex-wrap">
          {selectedOptions.slice(0, 2).map((option) => (
            <Chip
              key={option}
              label={option}
              onRemove={() => handleRemoveChip(option)}
            />
          ))}
          {selectedOptions.length > 2 && (
            <Chip
              label={`+ ${selectedOptions.length - 2} more`}
              onRemove={() => {}}
            />
          )}
        </div>

        {/* Placeholder */}
        {selectedOptions.length === 0 && (placeholder || 'Select options')}

        <span className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 transform">
          {isOpen ? (
            <svg
              className="h-4 w-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
          ) : (
            <svg
              className="h-4 w-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </span>
      </div>

      {/* Dropdown Section */}
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full rounded-xl border border-gray-200 bg-white p-3 shadow-lg dark:border-white/10 dark:bg-gray-500 dark:text-white">
          {isLoading ? (
            <div className="text-center text-sm">Loading...</div>
          ) : hasNoData || filteredOptions.length === 0 ? (
            <div className="text-center text-sm">No Data Available</div>
          ) : (
            <>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-2 w-full rounded-md border border-gray-300 p-2 text-sm"
              />
              <div className="mb-2 flex justify-between">
                {multipleoptions !== 'NO' && (
                  <>
                    <button
                      onClick={handleSelectAll}
                      className="text-sm text-blue-500"
                    >
                      Select All
                    </button>
                    <button
                      onClick={handleClearAll}
                      className="text-sm text-red-500"
                    >
                      Clear All
                    </button>
                  </>
                )}
              </div>
              <div className="max-h-40 overflow-y-auto">
                {/* {
                    console.log(filteredOptions)

                } */}
                {multipleoptions !== 'NO'
                  ? filteredOptions.map((option, index) => (
                      <label key={index} htmlFor={`checkbox-${option.value}`} className="block p-2">
                        {/* {console.log(

selectedOptions.length, selectedOptions, selectedOptions.indexOf(option.label),option.label

                        )} */}
                        <input
                        id={`checkbox-${option.value}`}
                          type="checkbox"
                          value={option.value}
                          checked={selectedOptions.includes(option.label)}
                          onChange={() => handleSelect(option)}
                          className="mr-2"
                        />
                        {option.label}
                      </label>
                    ))
                  : filteredOptions.map((option, index) => (
                      <label key={index} className="block p-2">
                        <span
                          onClick={() => handleSelect(option)}
                          className={`cursor-pointer ${
                            selectedOptions.includes(option.label)
                              ? 'font-bold'
                              : ''
                          }`}
                        >
                          {option.label}
                        </span>
                      </label>
                    ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(FindingsDropdown);
